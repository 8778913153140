/* eslint-disable import/order */
import { AppProps } from 'next/app'
import Head from 'next/head'
import 'react-spring-bottom-sheet/dist/style.css'
import '../base-styles.scss'
import '@wetaca/wetaca-components/dist/index.css'

function App({ Component, pageProps }: AppProps) {
  return (
    <div suppressHydrationWarning>
      <Head>
        <meta charSet='utf-8' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-title' content='Wetaca' />
        <meta name='viewport' content='width=device-width' />
        <meta name='theme-color' content='#000000' />
        <meta name='google-site-verification' content='mL4d6DDPZ1PXPIOlipK0W8CgNblTmAnYMFv489dwbAU' />
        <title>Wetaca</title>
      </Head>
      <Component {...pageProps} />
    </div>
  )
}
export default App
